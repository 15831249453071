import axios from 'axios'
import endpoints from './endpoints.json'
import { HttpError } from 'react-admin';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getExternalChannels = async(params)=>{
    const { page, perPage } = params.pagination;
    const project = params.filter.project === undefined ? '_kids' : params.filter.project
    const queryParams ={
        project:project,
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        searchPhrase: params.filter.searchPhrase,
        sort: params.sort.field,
        order: params.sort.order
    }
   // console.log(queryParams)
        const rawData = await axios.get(endpoints.backendUrl+endpoints.externalChannelsEndpoint, { params: queryParams, headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
       // console.log(rawData)
        const mappedData = rawData.data.map(entry=>({
            id:`${project}@${entry.channel_id}`,
            title:entry.title, 
            thumbnail: entry.thumbnail,
            url: entry.url,
            lastUpdate: entry.lastUpdate.slice(0,entry.lastUpdate.lastIndexOf('.')), 
            subscribers:entry.subscribers.toLocaleString(),
            videos: entry.videos.toLocaleString(),
            views: entry.views.toLocaleString(),
            averageViewsLast2h: entry.averageViewsLast2h == null ? 0 : entry.averageViewsLast2h.toLocaleString(),
            project: params.filter.project}))
        return {data:mappedData,
            pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: mappedData.length < perPage ? false : true}
        }
}
export const getManyExternalChannels = async(params)=>{
    const project = params.ids[0].substring(0,params.ids[0].indexOf('@'))
    const ids = params.ids.map(entry=> entry.slice(entry.indexOf('@')+1))
    const queryParams ={
        project:project,
        ids: ids.join()
    }
        const rawData = await axios.get(endpoints.backendUrl+endpoints.externalChannelsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
       // console.log(rawData)
        const mappedData = rawData.data.map(entry=>({
            id:`${project}@${entry.channel_id}`,
            thumbnail: entry.thumbnail,
            url: entry.url,
            title:entry.title, 
            lastUpdate: entry.lastUpdate.slice(0,entry.lastUpdate.lastIndexOf('.')),
            subscribers:entry.subscribers.toLocaleString(),
            videos: entry.videos.toLocaleString(),
            views: entry.views.toLocaleString(),
            averageViewsLast2h: entry.averageViewsLast2h == null ? 0 : entry.averageViewsLast2h.toLocaleString(),
            project: project}))
        //console.log(mappedData)
        return {data:mappedData}
}
export const getExternalChannel = async(params)=>{
    const project = params.id.substring(0,params.id.indexOf('@'))
    const id = params.id.slice(params.id.indexOf('@')+1)
    const queryParams ={
        project:project,
        ids: id
    }
   // console.log(params.id)
        const rawData = await axios.get(endpoints.backendUrl+endpoints.externalChannelsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
       // console.log(rawData)
        const mappedData = rawData.data.map(entry=>({
            id:`${project}@${entry.channel_id}`,
            thumbnail: entry.thumbnail,
            url: entry.url,
            title:entry.title, 
            lastUpdate: entry.lastUpdate.slice(0,entry.lastUpdate.lastIndexOf('.')), 
            subscribers:entry.subscribers.toLocaleString(),
            videos: entry.videos.toLocaleString(),
            views: entry.views.toLocaleString(),
            averageViewsLast2h: entry.averageViewsLast2h == null ? 0 : entry.averageViewsLast2h.toLocaleString(),
            project: project}))
       // console.log(mappedData)
        return {data:mappedData[0]}
}
export const createExternalChannel = async(params)=>{
    
    if(params.data.project === '')
        return Promise.reject(new HttpError(
            "No Project selected",
            404,
            {}
        ))
    let videoIds = params.data.videoIds.split(/\r?\n/)
    videoIds = videoIds.map(entry=>extractVideoId(entry))
    const mappedParams = {
        project: params.data.project,
        videoIds: videoIds

    }
    const rawData = await axios.post(endpoints.backendUrl+endpoints.externalChannelsEndpoint ,mappedParams, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
   // console.log(rawData)
    const mappedData = {
        id:rawData.data[0].channel_id,
        title:rawData.data[0].title, 
        lastUpdate: rawData.data[0].lastUpdate, 
        subscribers:rawData.data[0].subscribers.toLocaleString(),
        videos: rawData.data[0].videoCount.toLocaleString(),
        views: rawData.data[0].totalViews.toLocaleString(),
        averageViewsLast2h: 0,
        project: params.data.project}
    return {data:mappedData}
}
export const deleteExternalChannel = async(params)=>{
    const id = params.id.slice(params.id.indexOf('@')+1)
    const data = params.previousData
    await axios.delete(endpoints.backendUrl+endpoints.externalChannelsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {channelId: id, project:data.project}})
    return {data:data}
}
export const deleteExternalChannels = async(project, ids)=>{
    const clearedIds = ids.map(id => id.slice(id.indexOf('@')+1))  
    await axios.delete(endpoints.backendUrl+endpoints.externalChannelsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {channelId: clearedIds.join(), project:project}})
    return {data:ids}
}

export const getExternalVideos = async(params) =>{
    const { page, perPage } = params.pagination;   
    const project = params.filter.project === undefined ? '_kids' : params.filter.project
    const queryParams ={
        project:project,
        searchPhrase: params.filter.searchPhrase,
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field === 'id' ? 'viewsLast2h' : params.sort.field,
        order: params.sort.field === 'id' ? 'DESC' : params.sort.order
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.externalChannelsEndpoint+endpoints.externalVideoEndpoint+'/list', {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
    let mappedData = rawData.data.map(entry=>({
        id: project +'@'+ entry.videoId,
        videoId: entry.videoId,
        title: entry.title,
        originalTitle: entry.originalTitle,
        thumbnail: entry.thumbnail === "" ? "https://flocal.s3.us-west-1.amazonaws.com/general/noThumbnailPlaceholder.png" : entry.thumbnail?.replaceAll("'",""),
        publishDate: entry.publishDate?.substring(0,entry.publishDate.lastIndexOf(':')).replace("T"," "),
        views: entry.views?.toLocaleString(),
        viewsFirst24h: entry.viewsFirst24h?.toLocaleString(),
        viewsFirstWeek: entry.viewsFirstWeek?.toLocaleString(),
        likes: entry.likes?.toLocaleString(),
        rating: entry.rating,
        viewsPerLike: entry.viewsPerLike,
        tags: entry.tags,
        duration: entry.duration?.replace("PT","").replace("H"," h ").replace("M","min ").replace("S","sec"),
        channel_id: `${project}@${entry.channel_id}`,
        viewsLast2h: entry.viewsLast2h?.toLocaleString(),
        viewsLast24h: entry.viewsLast24h,
        viewsLast24hSum: entry.viewsLast24hSum?.toLocaleString(),
        videoPerformance: entry.videoPerformance,
        lastUpdate: entry.lastUpdate,
        channelTitle: entry.channelTitle,
        hasRetentionSpikes: entry.hasRetentionSpikes,
        madeForKids: entry.madeForKids
    }))
    mappedData = mappedData.filter(item=> videoIsPublished(item.publishDate) )
   // console.log(mappedData)
    return {data:mappedData,
        pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: mappedData.length < perPage ? false : true}
    }
}

export const getExternalVideo = async(params) =>{
    const project = params.id.substring(0,params.id.indexOf('@')) 
    const id = params.id.slice(params.id.indexOf('@')+1)
    const queryParams ={
        project:project,
        videoIds: id
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.externalChannelsEndpoint+endpoints.externalVideoEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
    //console.log(rawData)
    let mappedData = rawData.data.map(entry=>({
        id: params.id,
        videoId: entry.videoId,
        project: project,
        title: entry.title,
        originalTitle: entry.originalTitle,
        thumbnail: entry.thumbnail.replaceAll("'",""),
        publishDate: entry.publishDate.substring(0,entry.publishDate.lastIndexOf(':')).replace("T"," "),
        views: entry.views.toLocaleString(),
        viewsFirst24h: entry.viewsFirst24h?.toLocaleString(),
        viewsFirstWeek: entry.viewsFirstWeek?.toLocaleString(),
        likes: entry.likes.toLocaleString(),
        rating: entry.rating,
        viewsPerLike: entry.viewsPerLike,
        tags: entry.tags,
        duration: entry.duration.replace("PT","").replace("H"," h ").replace("M","min ").replace("S","sec"),
        channel_id: `${project}@${entry.channel_id}`,
        viewsLast2h: entry.viewsLast2h.toLocaleString(),
        viewsLast24h: entry.viewsLast24h,
        viewsLast24hSum: entry.viewsLast24hSum.toLocaleString(),
        videoPerformance: entry.videoPerformance,
        lastUpdate: entry.lastUpdate,
        channelTitle: entry.channelTitle,
        hasRetentionSpikes: entry.hasRetentionSpikes,
        madeForKids: entry.madeForKids,
        retentionSpikes: []
    }))
    if(mappedData[0].hasRetentionSpikes === 1){
        const retentionSpikes = await getRetentionSpikes(mappedData[0].videoId)
        mappedData[0].retentionSpikes = retentionSpikes
    }
    return {data:mappedData[0]}
}

export const getExternalTopTags = async(params) =>{    
    const rawData = await axios.get(endpoints.backendUrl+endpoints.externalChannelsEndpoint+'/top', {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params})
    return {data:rawData}
}

export const getExternalSnapshots = async(params) =>{
    const videoId = params.id.slice(params.id.indexOf('@')+1)
    const mappedParams= {
        videoId:videoId
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.externalSnapshotsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params:mappedParams})
    return {data:rawData}
}
export const addExternalSnapshot = async(params) =>{
    const project = params.id.substring(0,params.id.indexOf('@')) 
    const id = params.id.slice(params.id.indexOf('@')+1)
    const mappedParams = {
        project : project,
        videoId : id,
        title : params.title,
        originalTitle : params.originalTitle,
        thumbnail : params.thumbnail,
        publishDate : params.publishDate,
        views : params.views.replaceAll(",",'').replaceAll(".",''),
        viewsFirst24h : params.viewsFirst24h.replaceAll(",",'').replaceAll(".",''),
        viewsFirstWeek : params.viewsFirstWeek.replaceAll(",",'').replaceAll(".",''),
        likes : params.likes.replaceAll(",",'').replaceAll(".",''),
        rating : params.rating == null ? 0 : params.rating,
        viewsPerLike : params.viewsPerLike,
        tags : params.tags,
        duration : params.duration,
        channel_id : params.channel_id,
        viewsLast2h : params.viewsLast2h.replaceAll(",",'').replaceAll(".",''),
        viewsLast24h : params.viewsLast24h,
        viewsLast24hSum : params.viewsLast24hSum.replaceAll(",",'').replaceAll(".",''),
        videoPerformance : params.videoPerformance
    }
    const rawData = await axios.post(endpoints.backendUrl+endpoints.externalSnapshotsEndpoint,mappedParams, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:rawData}
}
export const deleteExternalSnapshot = async(params) =>{
    const mappedParams= {
        videoId:params.id,
        timestamp: params.timestamp.replace("T",' ').replace(".000Z",'')
    }
    const rawData = await axios.delete(endpoints.backendUrl+endpoints.externalSnapshotsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: mappedParams})
    return {data:rawData}
}
//called from getExternalVideo if hasRetentionSpikes is true
const getRetentionSpikes = async(videoId) =>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.retentionSpikes, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: {videoId:videoId}})
    const mappedData = rawData.data.map(entry=>({...entry,
                                                    startTimeAsString:secondsToHms(entry.startTime),
                                                    durationAsString:secondsToHms(entry.duration),
                                                    url:`https://www.youtube.com/watch?v=${videoId}&t=${entry.startTime}`}))
    return mappedData
}

export const createRetentionSpike = async(params) =>{
    const startTime = parseInt(params.hours)*3600+parseInt(params.minutes)*60+parseInt(params.seconds)
    params = {...params,startTime:startTime}
    console.log(params)
    const rawData = await axios.post(endpoints.backendUrl+endpoints.retentionSpikes,params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:{id:rawData.data.insertId, ...params}}
}
export const deleteRetentionSpike = async(params) =>{
    const rawData = await axios.delete(endpoints.backendUrl+endpoints.retentionSpikes, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params})
    return {data:rawData}
}

//Helper Functions
const extractVideoId = (url) => {
    const videoId = url.split("v=")[1];
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
        return videoId.substring(0, ampersandPosition);
    }
    return videoId;
    }
// change seconds to hh:mm:ss
const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
}

const videoIsPublished = (publishDate) =>{
    const videoDate = new Date(publishDate);
    const currentDate = new Date();
    return videoDate.getTime() <= currentDate.getTime()
  }