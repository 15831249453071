import React, {useState, useEffect} from "react";
import { useDataProvider,} from 'react-admin';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/system/Unstable_Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function AddComparableVideoModal({channelId,setReload, setUpdate}) {
    const [open, setOpen] = useState(false);
    const [videos, setVideos] = useState([])
    const dataProvider = useDataProvider()
    const [filter, setFilter] = useState("publishDate")
    const [searchPhrase, setSearchPhrase] = useState("")

    useEffect(()=>{
      if(open){
        (async ()=>{
          const data = await dataProvider.getList("ownVideos",{pagination:{page:0,perPage:100},
                                                  filter:{Channel:channelId,
                                                        searchPhrase:searchPhrase},
                                                  sort:{field:filter, order:"DESC"}
                                    })
          setVideos(data.data)
        })()
      }
    },[open,filter,searchPhrase])


    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const addVideo = async (video)=>{
        const data = await dataProvider.getOne("ownVideos",{id:video.id})
        const videoWithRetention = data.data
        await dataProvider.setComparableVideo(video.channelId,videoWithRetention)
        setReload(Math.random())
        setUpdate(Math.random())
    }

    return (
        <div>
            <MaterialButton sx={{color:"#ffffff"}} onClick={handleClickOpen}>
            Add video to compare
            </MaterialButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                Add Videos to compare
                </BootstrapDialogTitle>
                <DialogContent dividers>
                <Filters filter={filter} setFilter={setFilter} searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase}/>
                {videos &&
                    <>  
                    {videos.map((video, index) => (
                      <Grid container spacing={2} key={index} alignItems="center">
                        <Grid xs={2}><img className='mousePointer' src={video.thumbnail} height="60px" /></Grid>
                        <Grid xs={8}>{video.title}</Grid>
                        <Grid xs={2}><MaterialButton onClick={e=> addVideo(video)}>Add</MaterialButton></Grid>
                      </Grid>
                    ))} 
                    </>
                }
                 </DialogContent>
                 
            </BootstrapDialog>
        </div>
  );
}

const Filters = ({filter, setFilter, searchPhrase, setSearchPhrase}) =>{
  return (
      <Grid container spacing={2} >
        <Grid xs={6} sx={{paddingTop:"0px"}}>
              <Search searchTerm ={searchPhrase} setSearchPhrase={setSearchPhrase}/>
          </Grid>
          <Grid xs={6}>
              <SelectFilter filter = {filter} setFilter={setFilter}/>
          </Grid>
      </Grid>
  )

}

const SelectFilter = ({filter, setFilter}) => {
  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label="Filter"
          onChange={handleChange}
        >
          <MenuItem value={"publishDate"}>Publish Date</MenuItem>
          <MenuItem value={"retentionPercentage"}>Retention</MenuItem>
          <MenuItem value={"retentionPercentageFirst3Min"}>Retention First 3 Minutes</MenuItem>
          <MenuItem value={"weightedRetentionPercentageFirstMin"}>Retention Score</MenuItem>
          <MenuItem value={"viewsLast2h"}>Views Last 2 Hours</MenuItem>
          <MenuItem value={"viewsLast24hSum"}>Views Last 24 Hours</MenuItem>
          <MenuItem value={"ctr"}>Click Through Rate</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}   
const Search = ({searchPhrase, setSearchPhrase}) => {
  const handleChange = (event) => {
    const text = event.target.value
    if(text.length > 2 || text.length === 0)
      setSearchPhrase(text);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <TextField label ="Search" defaultValue={searchPhrase}  onChange={handleChange} variant="filled" />
      </FormControl>
    </Box>
  );
}