import {useEffect, useState} from 'react'
import { Card } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom"
import { 
  Datagrid,  
  List, 
  TextField,
  SelectInput,
  SelectArrayInput,
  useRecordContext,
  useGetList,
  Create,
  SimpleForm,
  TextInput,
  DeleteButton,
  EditButton,
  useDataProvider,
  useGetOne, 
  useUpdate, 
  Title, 
  useNotify, 
  useRefresh,
  usePermissions,
  Pagination
   } from 'react-admin';

   export const UserList = () => {
    const {permissions} = usePermissions()
    return(
    <List title="Users" filters={UserFilters} pagination={<CustomPagination/>}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="team" />
          <UserRoles source="id" label="Roles"/>
          <UserChannels source="id" label="Channels"/>
          <TextField source="email" />
          <TextField source="Discord" />
          {(permissions === 'superuser' || permissions === 'manager') && 
          <>
            <EditButton/>
           <DeleteButton /> 
          </>}        
        </Datagrid>
    </List>
    )
}

const CustomPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;

const UserFilters = [
    <TextInput source="searchPhrase" label="Search" alwaysOn />,
  ]

  const UserRoles = ({source}) =>{
    const record = useRecordContext()
    const dataProvider = useDataProvider();
    const [roles, setRoles] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const result = await dataProvider.getUserRoles('userData', { id: record.id })
            setRoles(result?.data)
        }
        fetchData()
    }, [record.id, dataProvider])

    return record ? (
      <ul>
        {roles?.map(role => (
            <li key={role.id}>{role.name}</li>
        ))}
        </ul>
      ): null;
  }

  const UserChannels = ({source}) =>{
    const record = useRecordContext()
    const dataProvider = useDataProvider();
    const [channels, setChannels] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const result = await dataProvider.getUserChannels('userChannels', { id: record.id })
            setChannels(result?.data)
        }
        fetchData()
    }, [record.id, dataProvider])

    return record ? (
      <ul>
        {channels?.map(channel => (
            <li key={channel.id}>{channel.name}</li>
        ))}
        </ul>
      ): null;
  }

  export const UserCreate = () => (
    <Create>
      <SimpleForm>
        <TextInput source='name' label='Name' resettable/>
        <SelectInput source ='team' choices={[
        { id: 'US', name: 'US' },
        { id: 'VN', name: 'VN' },
      ]}/>
        <TextInput source='email' label='Email' resettable/>
        <TextInput source='chatId' label='Discord Name' resettable/>
        <UserRolesShow/>
        <UserChannelsShow/>
      </SimpleForm>
    </Create>
  )

  export const UserEdit = () => {
    const notify = useNotify()
    const refresh = useRefresh()
    const { id } = useParams();
    const { isLoading, data } = useGetOne("userData", { id });
    const [update, { isLoading: isSubmitting }] = useUpdate();
    const navigate = useNavigate();
    const onSubmit = (data) => {   
        data.id = id 
        update(
            "userData",
            { id, data },
            { onSuccess: () => { 
                refresh()
                notify('User updated', 'success')
                navigate('/userData'); } }
        );
    };
    if (isLoading) return null;
    return (
        <div>
            <Title title="Edit User" />
            <Card>  
                <SimpleForm onSubmit={onSubmit}>
                    <TextInput source='name' label='Name' resettable defaultValue={data.name}/>
                    <SelectInput source ='team' choices={[
                    { id: 'US', name: 'US' },
                    { id: 'VN', name: 'VN' }, 
                ]} defaultValue={data.team}/>
                    <TextInput source='email' label='Email' resettable/>
                    <TextInput source='chatId' label='Discord Name' resettable/>
                    <UserRolesEdit record = {data}/>
                    <UserChannelsEdit record = {data}/>
                </SimpleForm>
            </Card>
        </div>
  )
}

const UserRolesShow = ({record}) =>{

    const { data, isLoading, error } = useGetList(
        'roles',
        { 
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'id', order: 'DESC' }
        }
    );
    
    if (isLoading) { return (
        <SelectArrayInput source="roles" label ='Loading Roles'/>
    ) }
    if (error) { return <p>ERROR</p>; }
    const roles = data.map(role => ({id: role.id, name: role.name}))
    return (    
        <SelectArrayInput  source="roles" label ='Roles' choices={roles} />
    )
  }
  
  const UserRolesEdit = ({record}) =>{

    const { data, isLoading, error } = useGetList(
        'roles',
        { 
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'id', order: 'DESC' }
        }
    );
    
    if (!record || !record['roles'] || isLoading) { return (
        <SelectArrayInput source="roles" label ='Loading Roles'/>
    ) }
    if (error) { return <p>ERROR</p>; }
    const selectedRoles = record['roles'].map(role => role.id)
    const roles = data.map(role => ({id: role.id, name: role.name}))
    return (    
        <SelectArrayInput  source="roles" label ='Roles' choices={roles} defaultValue={selectedRoles}/>
    )
  }

  const UserChannelsShow = ({record}) =>{

    const { data, isLoading, error } = useGetList(
        'userChannels',
        { 
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'id', order: 'DESC' }
        }
    );
    
    if (isLoading) { return (
        <SelectArrayInput source="channels" label ='Loading Channels'/>
    ) }
    if (error) { return <p>ERROR</p>; }
    const channels = data.map(role => ({id: role.id, name: role.name}))
    return (    
        <SelectArrayInput  source="channels" label ='Channels' choices={channels} />
    )
  }
  
  const UserChannelsEdit = ({record}) =>{

    const { data, isLoading, error } = useGetList(
        'userChannels',
        { 
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'id', order: 'DESC' }
        }
    );
    
    if (!record || !record['channels'] || isLoading) { return (
        <SelectArrayInput source="channels" label ='Loading Channels'/>
    ) }
    if (error) { return <p>ERROR</p>; }
    const selectedChannels = record['channels'].map(role => role.id)
    const channels = data.map(role => ({id: role.id, name: role.name}))
    return (    
        <SelectArrayInput  source="channels" label ='Channels' choices={channels} defaultValue={selectedChannels}/>
    )
  }