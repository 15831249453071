import axios from 'axios'
import endpoints from './endpoints.json'

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const createPrompt = async(params)=>{
    console.log(params)
    const rawData = await axios.post(endpoints.backendUrl+endpoints.promptsEndpoint, params.data, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const result = {...params, id: rawData.data.insertId}
    console.log(result)
    return {data: result}
}

export const deletePrompt = async(params)=>{
    await axios.delete(endpoints.backendUrl+endpoints.promptsEndpoint +`?id=${params.id}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return true
}

export const updatePrompt = async(params)=>{
    console.log(params)
    await axios.put(endpoints.backendUrl+endpoints.promptsEndpoint, params.data, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return params
}

export const getPrompts = async(params)=>{
    const rawData = await axios.get(endpoints.backendUrl+endpoints.promptsEndpoint + `?owner=${params.meta.owner}&searchTerm=${params.meta.searchTerm}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const sortedData = sortJsonArrayByTitle(rawData.data)
    return {data: sortedData, total: sortedData.length}
}

const sortJsonArrayByTitle = (jsonArray) =>{
    // Assuming jsonArray is an array of objects and each object has a 'title' property
    return jsonArray.sort((a, b) => {
        // Compare the titles alphabetically
        const titleA = a.title.toUpperCase(); // to handle case-insensitive sorting
        const titleB = b.title.toUpperCase(); // to handle case-insensitive sorting

        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }

        // titles must be equal
        return 0;
    });
}
