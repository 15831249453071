import {
    useShowContext, 
    Button,
    useNotify,
    useDataProvider    
} from 'react-admin';
import React, {useState, useEffect} from "react";
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';

const AddSnapshotButton = ({setRefresh}) =>{
    const data = useShowContext()
    const notify = useNotify()
    const dataProvider = useDataProvider()
    
    const addSnapshot = async ()=>{
       const result = await dataProvider.addExternalSnapshot('externalChannels',data.record)
        if(result){
            notify('Snapshot created', {type:'success'})
            setRefresh(Math.random())
        }
    }
    
    return <Button onClick={(e)=>addSnapshot()} label='Add Snapshot'/>
}

const DeleteSnapshotButton = ({id, timestamp, setRefresh}) =>{
    const notify = useNotify()
    const dataProvider = useDataProvider()
    
    const deleteSnapshot = async ()=>{
       const result = await dataProvider.deleteExternalSnapshot('externalChannels',{id:id, timestamp:timestamp})
        if(result){
            notify('Snapshot Deleted', {type:'success'})
            setRefresh(Math.random())
        }
    }
    
    return <Button onClick={(e)=>deleteSnapshot()} label='Delete'/>
}

export const ExternalSnapshots = () =>{
    const [snapshots,setSnapshots] = useState([])
    const [refresh, setRefresh] = useState(0)
    const data = useShowContext()
    const notify = useNotify()
    const dataProvider = useDataProvider()
    useEffect(()=>{
        getSnapshots()
    },[refresh])
    const getSnapshots = async ()=>{
       const result = await dataProvider.getExternalSnapshots('externalChannels',{id:data.record.id})
        if(!result){
            notify('No Snapshots found', {type:'warning'})
        }
        else{
            //console.log(result.data)
            setSnapshots(result.data.data)
        }
    }
    if(!snapshots || snapshots.length === 0) return (
        <Box sx={{ flexGrow: 1 }}>
        <Grid xs={2}><AddSnapshotButton setRefresh ={setRefresh}/></Grid>
        </Box>
    )
    return <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >
                    <Grid xs={2}><AddSnapshotButton setRefresh ={setRefresh}/></Grid>
                    <Grid xs={2}><b>Title</b></Grid>
                    <Grid xs={2}><b>Original Title</b></Grid>       
                    <Grid xs={2}><b>Duration</b></Grid>
                    <Grid xs={2}><b>Views</b></Grid>
                    <Grid xs={2}><b>Views last 24 Hours</b></Grid>
            </Grid>
        </Box>
           
    {snapshots.map((data, i) => <Snapshot key={i} snapshotData ={data} setRefresh ={setRefresh}/>)}</>
}

const Snapshot = ({snapshotData, setRefresh}) =>{
    return(
        <Box sx={{border:1, borderColor:'grey.300' }}>
            <Grid container spacing={2}>
                <Grid xs={12}><b>{snapshotData.timestamp.replace("T",' ').replace(".000Z",'')}</b></Grid>
            
                <Grid container spacing={2} columns ={13}>
                    <Grid xs={2}><img src = {snapshotData.thumbnail.replaceAll("'",'')} alt={snapshotData.title}/></Grid>
                    <Grid xs={2}>{snapshotData.title}</Grid>
                    <Grid xs={2}>{snapshotData.originalTitle}</Grid>        
                    <Grid xs={2}>{snapshotData.duration}</Grid> 
                    <Grid xs={2}>{snapshotData.views.toLocaleString()}</Grid> 
                    <Grid xs={2}>{snapshotData.viewsLast24hSum.toLocaleString()}</Grid>
                    <Grid xs={1}><DeleteSnapshotButton id={snapshotData.videoId} timestamp ={snapshotData.timestamp} setRefresh ={setRefresh}/></Grid>
                </Grid>
            </Grid>
    </Box>
    )
}