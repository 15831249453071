import React,{useState, useEffect} from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDataProvider} from 'react-admin';

export const SelectChannel = ({channel, setChannel}) => {
    const [ownChannels, setOwnChannels] = useState([])
    const dataProvider = useDataProvider()
    useEffect(() => {
      dataProvider.getList('ownChannels', {pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}}).then(result=>{
        const data = result.data.map(channel=>{return {id: channel.id, name: channel.name, project: channel.project}})
        setOwnChannels(data)
        return dataProvider.getDefaultChannel()
      }
      ).then(result => {
        if (result)
         setChannel(result)
      }).catch()
    }, [])
  
    const handleChange = async (event) => {
          const selectedChannel = ownChannels.find(channel=>channel.id === event.target.value)
          if(!selectedChannel) return
          setChannel(selectedChannel);
          await dataProvider.setDefaultChannel(selectedChannel)
    };
    if(!channel) return null
    return (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Channel</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={channel.id}
              defaultValue = {channel.id}
              label="Filter"
              onChange={handleChange}
            >
              {ownChannels.map(channel => <MenuItem value={channel.id}>{channel.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      );
    }