import React,{useState, useEffect} from "react";
import { 
    Card, 
    CardContent, 
 } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 
 { 
    useDataProvider, 
    Button,
    useGetIdentity,
    useNotify,
    Title,
    Loading
    } 
    from 'react-admin';
import {SelectChannel} from '../miscellanious/utils'
import { Audio } from 'react-loader-spinner'


const ShowVideoIdeas = () => {
    const [channel, setChannel] = useState({id: 1, name: 'Toys and Colors', project: '_kids'})

    return(
    <><Title title="Video Ideas List (updated every Monday)" />
    <Grid container>
        <Grid xs={12}>
        <SelectChannel channel ={channel} setChannel={setChannel}/>
        </Grid>
        <Grid xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2} >                        
                        <VideoIdeas channel={channel} title='Video Ideas (updated every Monday)'/>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    </>   
);
}
export default ShowVideoIdeas;

const VideoIdeas =({channel})=>{
    const notify = useNotify()
    const dataProvider = useDataProvider()
    const [reload, setReload] = useState(0)
    const [hasReserved, setHasReserved] = useState(false)
    const { data: identity, isLoading: identityLoading } = useGetIdentity();
    const [videoIdeas, setVideoIdeas] = useState([])
    useEffect(() => {
        if(channel.id){
            const data = dataProvider.getVideoIdeas(channel.id) 
            .then(data => {
                setVideoIdeas(data)
                const hasReserved = data.find((videoIdea)=>videoIdea.isReserved && videoIdea.reserverEmail === identity.fullName)
                setHasReserved(hasReserved)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [channel,reload])

    const reserveVideoIdea = (id, idea, sourceVideoIds, unreserve) => {
        if(hasReserved && !unreserve){
            notify('You have already reserved a video idea')
            return
        } 
        dataProvider.reserveVideoIdea(id, channel.id, identity.fullName).then(data => {setReload(Math.random())}).catch((error) => {console.log(error)})
        if(unreserve)
            dataProvider.delete('savedVideoIdeas',{id: id, idea: idea, owner: identity.fullName}).then().catch((error) => {console.log(error)})
        else
            dataProvider.create('savedVideoIdeas', {ownedChannelsId: channel.id, idea: idea, sourceVideoIds: JSON.stringify(sourceVideoIds), owner: identity.fullName, status: "pending"}).then().catch((error) => {console.log(error)})
    }
    

    if (!videoIdeas || identityLoading) { return <p>Loading..</p>; }
    return (
        <Card elevation={3} style={{padding:"5px"}}>        
        <Grid xs={12}>
             <Grid container spacing={2} >
                {videoIdeas.map((row, index) => (       
                <Grid xs={12}>
                    <Grid container spacing={2} key={index} sx={index % 2 === 0 ? {backGroundColor:"white"} : {backgroundColor:"#F5F5F5"}} >
                        <Grid xs={12} sx={{marginTop: "10px", paddingBottom:0}}>
                            {row.isReserved 
                            ?(row.reserverEmail === identity.fullName ? <><b> {index + 1}. Reserved by you</b> <Button onClick={(e) => reserveVideoIdea(row.id, row.idea, row.sourceVideoIds, true)}>Unreserve</Button></> : <b>{index + 1}. Reserved by {row.reserverEmail}</b>)
                            :<><b>{index + 1}.</b><Button onClick= {(e)=> reserveVideoIdea(row.id, row.idea,row.sourceVideoIds, false)}>Reserve</Button></>}
                         </Grid>
                        <Grid xs={12} sx={{paddingTop: 0}}> <p>{row.idea}</p></Grid> 
                        <Grid xs={12} sx={{paddingTop: 0}}> <b>based on</b></Grid>
                        <>
                            {row.sourceVideoIds?.map((row, index) => (
                                <Grid xs={12} key={index}>
                                    <a href = {`https://www.youtube.com/watch?v=${row.videoId}`} target="_blank"> {row.title}</a>
                                </Grid> 
                            ))}
                        </>   
                        <Grid xs={12}>
                            <Grid container spacing={2} >
                            {row.sourceVideoIds?.map((row, index) => (
                                <Grid xs={3} key={index}>
                                    <a href = {`https://i.ytimg.com/vi/${row.videoId}/maxresdefault.jpg`} target="_blank"><img src ={`https://i.ytimg.com/vi/${row.videoId}/default.jpg`} width={160} height={120}/></a>
                                </Grid> 
                            ))}
                            </Grid>
                        </Grid> 
                    </Grid>
                </Grid>                  
            ))}  
            </Grid>       
        </Grid> 
        </Card>     
    );
}

export const SavedVideoIdeas = () => {
    const [reload,setReload] = useState(0)
    const dataProvider = useDataProvider()
    const [channel, setChannel] = useState({id: 1, name: 'Toys and Colors', project: '_kids'})
    const [tab, setTab] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);
    const[savedVideoIdeas, setSavedVideoIdeas] = useState([])
    const { data: identity, isLoading: identityLoading } = useGetIdentity();

    useEffect(() => {
        if(!identity) return
        dataProvider.getList('savedVideoIdeas', { meta: { ownedChannelsId: channel.id, owner: identity.fullName } }).then(data => {
            setSavedVideoIdeas(data.data[0])
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setError(error)
            setLoading(false)})
    }, [identity, reload, channel])

    const handleChange = (event, newValue) => {
        setTab(newValue);
      };

    if (loading || identityLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return(
    <><Title title="Saved Video Ideas" />
    <Grid container>
        <Grid xs={12}>
        <SelectChannel channel ={channel} setChannel={setChannel}/>
        </Grid>
        <Grid xs={12}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleChange} >
                    <Tab label="My Saved Ideas" />
                    <Tab label="All Saved Ideas" />
                    </Tabs>
                </Box>
                {tab === 0 && <SavedVideoIdeasContent content = {savedVideoIdeas.mySavedVideoIdeas} myIdeas ={true} owner ={identity.fullName} setReload ={setReload}/>}
                {tab === 1 && <SavedVideoIdeasContent content = {savedVideoIdeas.savedVideoIdeas} myIdeas ={false} owner={null} setReload ={setReload}/>}
            </Box>
        </Grid>
    </Grid>
    </>   
);
}
const SavedVideoIdeasContent = ({content, myIdeas, owner, setReload})=>{
    const dataProvider = useDataProvider()
    const [generatingThumbnail, setGeneratingThumbnail] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const deleteVideoIdea = async (id, idea) => {
        if(owner){
            await dataProvider.delete('savedVideoIdeas',{id: id, idea: idea, owner: owner}).then().catch((error) => {console.log(error)})
            setReload(Math.random())
        }
    }
    const generateThumbnailIdea = async (id, idea) => {
        setGeneratingThumbnail(true)
        setCurrentId(id)
        await dataProvider.generateThumbnailIdeas({id: id, script: idea}).then(result => setReload(Math.random())).catch((error) => {console.log(error)}).finally(()=>{
            setGeneratingThumbnail(false)
            setCurrentId(null)
        })
    }
    return(
        <Card elevation={3} style={{padding:"5px"}}>        
        <Grid xs={12}>
             <Grid container spacing={2} >
                {content?.map((row, index) => (       
                <Grid xs={12} sx={{marginLeft:"5px"}}>
                    <Grid container spacing={2} key={index} sx={index % 2 === 0 ? {backGroundColor:"white"} : {backgroundColor:"#F5F5F5"}} >
                        <Grid xs={12} sx={{marginTop: "10px", paddingBottom:0}}> 
                            <b>{index + 1}. 
                            {myIdeas ? <Button onClick={(e) => deleteVideoIdea(row.id, row.idea)}>Delete</Button>
                            :<> Reserved by {row.owner} at {row.timestamp}</>}
                            </b>
                         </Grid>
                        <Grid xs={12} sx={{paddingTop: 0}}> <p>{row.idea}</p></Grid> 
                        <Grid xs={12} sx={{paddingTop: 0}}> <b>based on</b></Grid>
                        <>
                            {row.sourceVideoIds?.map((row, index) => (
                                <Grid xs={12} key={index}>
                                    <a href = {`https://www.youtube.com/watch?v=${row.videoId}`} target="_blank"> {row.title}</a>
                                </Grid> 
                            ))}
                        </>   
                        <Grid xs={12}>
                            <Grid container spacing={2} >
                            {row.sourceVideoIds?.map((row, index) => (
                                <Grid xs={3} key={index}>
                                    <a href = {`https://i.ytimg.com/vi/${row.videoId}/maxresdefault.jpg`} target="_blank"><img src ={`https://i.ytimg.com/vi/${row.videoId}/default.jpg`} width={160} height={120}/></a>
                                </Grid> 
                            ))}
                            </Grid>
                        </Grid> 
                    </Grid>
                    <Grid xs={12} sx={index % 2 === 0 ? {backGroundColor:"white"} : {backgroundColor:"#F5F5F5"}} ><b>Thumbnail Idea</b></Grid>
                    {generatingThumbnail && currentId === row.id ? <Audio height="30" width="30" radius="9" color="#3596F3" /> :
                    <>
                    <Grid xs={12}>
                        {row.thumbnailIdea &&
                        <a href = {`${row.thumbnailIdea}`} target="_blank"><img src ={`${row.thumbnailIdea}`} width={160}/></a>}
                    </Grid>
                    <Grid xs={12}><Button onClick={(e) => generateThumbnailIdea(row.id, row.idea)}>Generate new Thumbnail Idea</Button></Grid>
                    </>}
                </Grid>                  
            ))}  
            </Grid>       
        </Grid> 
        </Card>
    )
}
