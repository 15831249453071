import {SimpleShowLayout, Title, useRefresh,RecordContextProvider, useRecordContext,TopToolbar, ListButton, useGetOne, useRedirect, DateInput} from 'react-admin';
import Grid from '@mui/system/Unstable_Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React, {useRef,useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import {Chart, LineElement,BarController, BarElement, LinearScale, CategoryScale, PointElement, Tooltip, Filler} from 'chart.js'
Chart.register(LineElement,BarController,BarElement, LinearScale, CategoryScale, PointElement, Tooltip, Filler)
let  myChart = null

 export const ViewAnalyticsShow = () => {
    const { id } = useParams();
    const [date,setDate] = useState(new Date().toISOString().split('T')[0])
    const redirect = useRedirect();
    const refresh = useRefresh();
    
    const { data, isLoading } = useGetOne(
        'viewsAnalytics',
        { id:id, meta:{date:date} },
        { onError: () => redirect('/viewsAnalytics') }
    );

    const onDateFilterChange = (e)=>{
        setDate(e.target.value)
        refresh()
    }
        
        return (
            <RecordContextProvider value={data}>
            <Title title = {<ShowAnalyticsTitle/>}/>
                <TopToolbar>
                    <input type='date' label="Date"  alwaysOn onChange={e => onDateFilterChange(e)}/>
                    <ListButton />
                </TopToolbar>
                <SimpleShowLayout>
                    <DailyViews record={data}/>
                </SimpleShowLayout>
            </RecordContextProvider>
        );
    }

const ShowAnalyticsTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Views Analytics: {record.name}</span>;
}

const DailyViews = ({record}) =>{
    if (!record) return null;
    if(record.dailyViewsPerChannel?.length === 0) return <h2>No Daily Views Data for the selected Date</h2>
    let gridSize = Math.floor(12/(record.dailyViewsPerChannel?.length+1))
    gridSize = gridSize > 3 ? 3 : gridSize
    return <>
        <h2>Views</h2>
        <Grid container spacing={2} >
        <Grid xs={gridSize} key={0}>
                    <Card sx={{backgroundColor:"#b3e5fc"}}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }}><b>Total</b> </Typography>  
                            <Typography sx={{ fontSize: 14 }}> Month to Date: {getTotalViewsThisMonth(record.dailyViewsPerChannel)}</Typography>
                            <Typography sx={{ fontSize: 14 }}> {getLatestViewsDate(record.dailyViewsPerChannel)}: {getTotalViewsLastDay(record.dailyViewsPerChannel)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
        {record.dailyViewsPerChannel?.map((item, index) => (
            <>
                <Grid xs={gridSize} key={index+1}>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }}><b>{item.channelName}</b> </Typography>  
                            <Typography sx={{ fontSize: 14 }}> Month to Date: {item.dailyViews[0].viewsMonthToDate?.toLocaleString()}</Typography>
                            <Typography sx={{ fontSize: 14 }}> {item.dailyViews[0].date}: {item.dailyViews[0].views?.toLocaleString()}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </>
            ))}
        </Grid>
        <h2>Monthly Views this Year</h2>
        <ViewChart record ={record}/>
    </>
}
const getTotalViewsThisMonth = (dailyViewsPerChannel) =>{
    let total = 0
    dailyViewsPerChannel?.forEach(channel=>{
        total += channel.dailyViews[0].viewsMonthToDate
    })
    return total.toLocaleString()
}
const getTotalViewsLastDay = (dailyViewsPerChannel) =>{
    let total = 0
    dailyViewsPerChannel?.forEach(channel=>{
        total += channel.dailyViews[0].views
    })
    return total.toLocaleString()
}
const getLatestViewsDate = (dailyViewsPerChannel) =>{
    if(!dailyViewsPerChannel) return null
    return dailyViewsPerChannel[0]?.dailyViews[0]?.date
}

const ViewChart =({record})=>{
    const ctx = useRef()

    useEffect(()=>{
      loadChart()
  })
  const loadChart = async ()=>{
    if (!record || ctx.current === null) return <></>;

    Tooltip.positioners.bottom = function(items, eventPosition) {      
        const chart = this.chart;
      
        return {
          x: chart.chartArea.left,
          y: chart.chartArea.bottom,
          xAlign: 'center',
          yAlign: 'bottom',
        };
      };
    let labels = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    //console.log(JSON.stringify(record.totalViewsPerMonthThisYear))
    const viewData = record.totalViewsPerMonthThisYear.map(view => view.viewsMonthToDate)
    
    if (myChart != null) {
        myChart.destroy();
    }
    myChart = new Chart(ctx.current, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [{
                label: "Total Monthly Views This Year",
                data: viewData,
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgb(75, 192, 192)'
            }
        ]
        },
        options: {
            interaction: {
                intersect: false,
                mode: 'index',
            }
        }
    })
  }
    return <div><canvas ref={ctx} width="300" height="100" ></canvas></div>
}