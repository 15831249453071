import {useEffect, useState} from 'react'
import {  
    List,  
    useListContext,
    useDataProvider,
    SelectInput
} from 'react-admin';
import {Table,TableBody,TableHead,TableCell,TableContainer,TableRow,Paper } from '@mui/material'


  export const ViewAnalyticsListLastXDays = () => {
    const [ownChannels, setOwnChannels] = useState([])
  const dataProvider = useDataProvider()
    useEffect(()=>{
      dataProvider.getList('ownChannels', {pagination: {page: 1, perPage: 500}, sort: {field: 'id', order: 'ASC'}}).then(result=>{
        let data = result.data.map(channel=>{return {id: channel.id, name: channel.name}})
        data = [
          {id: 100, name: "Kids Channels"},
          {id: 101, name: "Crafty Channels"},
          {id: 102, name: "US Craft Team"},
          {id: 103, name: "VN Craft Team"},
          {id: 104, name: "US Kids Team"},
          {id: 105, name: "VN Kids Team"},
          , ...data]
        setOwnChannels(data)
      }
      ).catch()
    },[])
  return (
  <List pagination={false} filters={getChannelFilters(ownChannels)}  >
        <UserTable/>
    </List>
  )
  }

  const getChannelFilters = (ownChannels) => [<SelectInput source ='channel' choices={ownChannels} emptyValue={-1} emptyText="All Channels" initialValue={-1} alwaysOn/>]


  const UserTable = ()=>{
    const {data} = useListContext()    
    if(!data || data.length === 0) return <p> No Data yet</p>
    let columnNames = Object.keys(data[0])
    columnNames = columnNames.filter((name)=>name!=="id" && name!=="NOT SET")
    return (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}  aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnNames.map((name, index)=><TableCell key={index} sx={{minWidth:"80px"}}><b>{name}</b></TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={row.id%2===0 ? {backgroundColor:"#f2f2f2",'&:last-child td, &:last-child th': { border: 0 }}:{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {columnNames.map((name,index)=><TableCell key={index} component="th" scope="row">
                {row[name]?.toLocaleString()}
              </TableCell>)}
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
      
  }

  const getThreeDaysAgo = ()=>{
    const date = new Date()
    date.setDate(date.getDate()-3)
    return date.toISOString().split('T')[0]
}