import { Auth } from 'aws-amplify';
Auth.configure({
    region: 'us-west-1',
    userPoolId: "us-west-1_676g5JNBl",
    userPoolWebClientId: '2oq5ufu9f7iubpcihf5vancf7l'
})

const authProvider = {
    login: async ({ username, password }) => {
        try{
            const user = await Auth.signIn(username, password)
           // console.log(JSON.stringify(user))
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                Auth.completeNewPassword(user, password).then(data=>{
                    return Auth.currentAuthenticatedUser()}).then(data =>{
                        localStorage.setItem('user', data.username)
                        localStorage.setItem('email', data.attributes.email)
                        localStorage.setItem('userType', data.signInUserSession.idToken.payload['cognito:groups'][0])
                        return Promise.resolve()
                    }).catch (error => {
                    console.log('error signing in', error);
                    window.alert(error)
                    return Promise.reject()
                })
            }
            else {
                Auth.currentAuthenticatedUser().then(data =>{
                    localStorage.setItem('email', data.attributes.email)
                    localStorage.setItem('user', data.username)
                    const userType = data.signInUserSession.idToken.payload['cognito:groups'] === undefined ? "standardUser" : data.signInUserSession.idToken.payload['cognito:groups'][0]
                    localStorage.setItem('userType', userType)
                    return Promise.resolve()
                    
                }).catch (error => {
                    console.log('error signing in', error);
                    window.alert(error)
                    return Promise.reject()
                })
            }
        }catch(error)
        {
            console.log('error signing in', error);
            window.alert(error)
            return Promise.reject()
        }
    },
    logout: () => {
        localStorage.removeItem('userType')
        localStorage.removeItem('user')
        localStorage.removeItem('email')
        localStorage.removeItem('idToken')
        localStorage.removeItem('tokenExpiration')
        return Promise.resolve();
    },
    checkAuth: () =>{
        return Auth.currentSession().then(session => {
            // Get the ID token from the session
            const idToken = session.getIdToken();
            localStorage.setItem('idToken', idToken.getJwtToken());
            localStorage.setItem('tokenExpiration', idToken.getExpiration());
            console.log(session)
            const currentTime = Math.floor(new Date() / 1000);
            // Check if the token has expired
            if (currentTime > idToken.getExpiration()) {
                // If the token has expired, clear local storage and reject
                localStorage.removeItem('userType');
                localStorage.removeItem('user');
                localStorage.removeItem('email');
                localStorage.removeItem('idToken');
                localStorage.removeItem('tokenExpiration');
                return Promise.reject();
            }
    
            // If the token is valid, resolve
            return Promise.resolve();
        }).catch(error => {
            // If an error occurs (e.g., no current session), clear local storage and reject
            localStorage.removeItem('userType');
            localStorage.removeItem('user');
            localStorage.removeItem('email');
            localStorage.removeItem('idToken');
            localStorage.removeItem('tokenExpiration');
            return Promise.reject();
        });
    },
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('userType')
            localStorage.removeItem('user')
            localStorage.removeItem('email')
            localStorage.removeItem('idToken')
            localStorage.removeItem('tokenExpiration')
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>{
        const userName = localStorage.getItem('user')
        const email = localStorage.getItem('email')
        return Promise.resolve({
            id: userName,
            fullName: email,
        })
    },
    getPermissions: () => {
        const role = localStorage.getItem('userType');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdToken: () => {
        return Auth.currentSession().then(session => {
            // Get the ID token from the session
            const idToken = session.getIdToken();
            return Promise.resolve(idToken.getJwtToken());
        }).catch(error => {
            // If an error occurs, reject
            return Promise.reject();
        });
    }
};

export default authProvider;

export const refreshAuth = async() => {
    const currentTime = Math.floor(new Date() / 1000);
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (!tokenExpiration) {
        console.log('No token expiration')
        return Promise.reject();
    }
    // Check if the token has expired
    if (currentTime > tokenExpiration) {
        console.log('Token refreshing')
        return Auth.currentSession().then(session => {
            // Get the ID token from the session
            const idToken = session.getIdToken();
            localStorage.setItem('idToken', idToken.getJwtToken());
            localStorage.setItem('tokenExpiration', idToken.getExpiration());
            return Promise.resolve();
        }).catch(error => {
            console.log('Error refreshing token', error);
            // If an error occurs (e.g., no current session), clear local storage and reject
            localStorage.removeItem('userType');
            localStorage.removeItem('user');
            localStorage.removeItem('email');
            localStorage.removeItem('idToken');
            localStorage.removeItem('tokenExpiration');
            return Promise.reject();
        });
    }
    console.log('Token not expired')
    return Promise.resolve();
}