import * as React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { RichTextInput } from 'ra-input-rich-text'
import {
  useRefresh,
  useNotify,
  useDataProvider,
  SimpleForm,
  NumberInput,
   } from 'react-admin';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function CreateSpikeDialog({project, videoId}) {
    const dataProvider = useDataProvider();
    const [open, setOpen] = React.useState(false);
    const [seconds, setSeconds] = React.useState("00")
    const [minutes, setMinutes] = React.useState("00")
    const [hours, setHours] = React.useState("00")
    const [duration, setDuration] = React.useState(0)
    const [description, setDescription] = React.useState('')
    const notify = useNotify()
    const refresh = useRefresh()
  
    const handleSave = () => {
      dataProvider.create("retentionSpikes", {project: project, externalVideosId: videoId, hours: hours, minutes:minutes, seconds: seconds, duration: duration, description: description})
      .then(() => {
        notify('Retention Spike created')
        setOpen(false)
        refresh()
      })
      .catch(error => {
        notify('Error: Retention Spike not created', 'warning')
      })
    }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MaterialButton  onClick={handleClickOpen}>
        Add Retention Spike
      </MaterialButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Retention Spike
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <SimpleForm toolbar={false}>
        <TimeInput hours={hours} setHours={setHours} minutes={minutes} setMinutes={setMinutes} seconds={seconds} setSeconds={setSeconds}/>
        <NumberInput source="duration" label="Duration in Seconds" onChange={e => setDuration(e.target.value)} />
        <RichTextInput source="description" label="Description" onChange={content => setDescription(content)} />
      </SimpleForm>
        </DialogContent>
        <DialogActions>
          <MaterialButton autoFocus onClick={handleSave}>
            Save changes
          </MaterialButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
const TimeInput = ({hours, minutes, seconds, setHours,setMinutes,setSeconds}) => {
  const style = {backgroundColor:"#F6F6F6", width:"20px", borderStyle:"none", borderBottom:"1px solid grey", padding:"6px", margin:"6px"}
  return (
    <div>
      Timestamp (HH:MM:SS)<br/>
      <input value={hours} style={style} onChange={e => setHours(e.target.value)} />
      :
      <input value={minutes} style={style} onChange={e => setMinutes(e.target.value)}/>
      :
      <input value={seconds} style={style} onChange={e => setSeconds(e.target.value)}/>
    </div>
  );
}
