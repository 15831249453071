import {useEffect, useState} from 'react'
import { 
  Datagrid,  
  List,  
  TextField,
  ExportButton,
  CreateButton,
  TopToolbar,
  SelectInput,
  TextInput,
  useRecordContext,
  useDataProvider,
  ShowButton,
  Button,
  useNotify,
  useListContext,
  usePermissions,
  Pagination,
  BooleanInput
   } from 'react-admin';
import Grid from '@mui/system/Unstable_Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {AddVideoCreatorDialog} from './AddVideoCreatorModal'
import {VideoPerformanceModal} from './VideoPerformancesModal'

export const OwnVideoList = () => {
    const {permissions} = usePermissions()
    const [threshold, setThreshold] = useState(0)
    const [averageRetention, setAverageRetention] = useState(0)
    const [averageRetentionFirstThirtySeconds, setAverageRetentionFirstThirtySeconds] = useState(0)
    const [averageweightedRetentionPercentageFirstMin, setAverageweightedRetentionPercentageFirstMin] = useState(0)
    const [averageCtr, setAverageCtr] = useState(0)
    const [ownChannels, setOwnChannels] = useState([])
    const [defaultChannel, setDefaultChannel] = useState(null)
    const [helperText, setHelperText] = useState('.')
    const dataProvider = useDataProvider()
    useEffect(()=>{
      (async () => {
        const result = await dataProvider.getList('ownChannels', {pagination: {page: 1, perPage: 500}, sort: {field: 'id', order: 'ASC'}})
        const data = result.data.map(channel=>{return {id: channel.id, name: channel.name, project: channel.project}})
        setOwnChannels(data)
        const defaultChannel = await dataProvider.getDefaultChannel()
        console.log(defaultChannel)
        if(defaultChannel){
          setDefaultChannel(defaultChannel)
        }
        else
          setDefaultChannel(data[0])
      })()
    },[])
    const handelMouseEnter = (e)=>{
      const title = helpers(e)
      if(title)
        setHelperText(title)
    }
    if(!defaultChannel) return null
    return (
    <List actions={<ListActions/>} filters={getVideoFilters(ownChannels, defaultChannel, setDefaultChannel)}  pagination={<CustomPagination/>} >
        <ChannelHeader channel = {defaultChannel} setAverageRetention={setAverageRetention} setAverageRetentionFirstThirtySeconds = {setAverageRetentionFirstThirtySeconds} setAverageCtr={setAverageCtr} setAverageweightedRetentionPercentageFirstMin = {setAverageweightedRetentionPercentageFirstMin} threshold={threshold} setThreshold={setThreshold}/>
        <HelperText helperText={helperText} threshold={threshold}/>
        <Datagrid bulkActionButtons={false} onMouseOver={handelMouseEnter} onMouseLeave={(e)=>setHelperText(".")}>
            <ShowButton label=''/>
            <Thumbnail/>
            <Link/>
            <TextField source='publishDate' label = 'Published' />
            <TextField source='duration' label = 'Duration' />
            <TextField source='retentionDuration' label = 'AVT' />
            <AverageTextField source='retentionPercentage_snapshot' label = 'Retention' average={averageRetention}/>
            <AverageTextField source='retentionFirstThirtySeconds_snapshot' label = '30sec' average={averageRetentionFirstThirtySeconds}/>
            <AverageTextField source='weightedRetentionPercentageFirstMin' label = 'Score' average={averageweightedRetentionPercentageFirstMin}/>
            <TextField source="views"  label = 'Views' />
            <TextField source="viewsLast2h" label = '2h'/>
            <TextField source="viewsLast24hSum" label = '24h'/>
            <AverageTextField source='ctr' label = 'CTR' average={averageCtr}/>
            <Creators label ="Creators" />
            {(permissions === 'superuser' || permissions === 'manager') && <AddVideoCreatorButton/> }
        </Datagrid>
    </List>
    )
    }
const CustomPagination = () => <Pagination rowsPerPageOptions={[25, 50, 100]} />;
const ChannelHeader = ({channel,setAverageRetention, setAverageRetentionFirstThirtySeconds,setAverageweightedRetentionPercentageFirstMin, setAverageCtr, threshold, setThreshold})=>{  
  //const {filterValues} = useListContext()
  const dataProvider = useDataProvider()
  const [channelAverage, setChannelAverage] = useState({})
  // const channel = filterValues.Channel
  useEffect(()=>{
   // console.log(channel)
  dataProvider.getChannelAverage('ownChannels', {channelId:channel.id}).then(result=>{
    setChannelAverage(result.data)
    setAverageRetention(result.data.averageRetentionPercentageSnapshot)
    setAverageRetentionFirstThirtySeconds(result.data.averageRetentionFirstThirtySecondsSnapshot)
    setAverageweightedRetentionPercentageFirstMin(result.data.averageWeightedRetentionFirstMinute)
    setAverageCtr(result.data.averageCtr)
    setThreshold(result.data.retentionSnapshotThreshold)
  }
  ).catch()
  },[channel])
  return (
    <><br/>
    <Grid container spacing={1} >   
      <Grid xs={2}>
        <Grid container spacing={0} >
          <Grid xs={3}>
            <Card sx={{marginBottom:"5px"}}>
              <CardContent sx={{fontSize:"small", fontWeight:"normal"}}>Retention at {threshold} views: {channelAverage?.averageRetentionPercentageSnapshot}%</CardContent>
            </Card><Card sx={{marginBottom:"5px"}}>
            <CardContent sx={{fontSize:"small", fontWeight:"normal"}}>First 30 Sec at {threshold} views: {channelAverage?.averageRetentionFirstThirtySecondsSnapshot}%</CardContent>
            </Card><Card sx={{marginBottom:"5px"}}>
            <CardContent sx={{fontSize:"small", fontWeight:"normal"}}>Retention Score: {channelAverage?.averageWeightedRetentionFirstMinute}</CardContent>
            </Card><Card sx={{marginBottom:"5px"}}>
            <CardContent sx={{fontSize:"small", fontWeight:"normal"}}>Retention Duration: {channelAverage?.averageRetentionDuration}</CardContent>
            </Card><Card sx={{marginBottom:"5px"}}>
            <CardContent sx={{fontSize:"small", fontWeight:"normal"}}>CTR (last 30 Videos): {channelAverage?.averageCtr}%</CardContent> 
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={10} sx={{height:"300px", overflow:"hidden"}} >
        <VideoPerformanceModal sx={{minWidth:"100%"}} channelId = {channel.id}/>        
      </Grid>
    </Grid>
    <br/>
    </>
  )
}
const getVideoFilters = (ownChannels, defaultChannel, setDefaultChannel) => {
  const handleChange = (e)=>{
    const channel = ownChannels.find(channel=>channel.id === e.target.value)
    if(!channel) return
    localStorage.setItem('defaultChannel', JSON.stringify(channel))
    setDefaultChannel(channel)
  }
return [
  <TextInput source="searchPhrase" label="Search" alwaysOn />,
  <SelectInput source ='Channel' choices= {ownChannels} initialValue={defaultChannel}  alwaysOn onChange={(e)=> handleChange(e)}/>,
  <SelectInput source ='publishDate' label='Publish Date' choices={[
    { id: 30, name: 'Last 30 Days' },
    { id: 90, name: 'Last 90 Days' },
    { id: 180, name: 'Last 6 Months' },
    { id: 365, name: 'Last Year' },
    { id: 10000, name: 'All Time' },
  ] } initialValue={10000} alwaysOn/>,
  <TextInput source="creatorSearch" label="Creator" alwaysOn />,
  <BooleanInput source="hasMultipleThumbnails" label="Show only Videos with multiple Thumnails" alwaysOn />,
]
}

const ListActions = () => (
  <TopToolbar>
      <CreateButton/>
      <ExportButton/>
      <ClearCompareVideosButton/>
  </TopToolbar>
);

const AverageTextField = ({source, average}) => {
  const record = useRecordContext();
  return record ? (
    <p style={{color: record[source]< 0.2 ? 'grey' :(record[source] > average ? 'green' : 'red')}}>{record[source]}
    {source !== 'weightedRetentionPercentageFirstMin' && <>%</>}</p>
  ) : null;
}

const Creators = ()=> {
  const [videoCreators, setVideoCreators] = useState([])
  const dataProvider = useDataProvider()
  const record = useRecordContext();
  useEffect(() => { 
    if(record){
        (async () => {
            const videoCreatorData = await dataProvider.getOwnVideoCreators({videoId: record['videoId']})
            setVideoCreators(videoCreatorData.data.map(creator=>creator.name))
        })()
    }
}, [])
  if (!record) return <></>;
  return videoCreators?.map(creator=>
    <p style={{fontSize:"10px"}}>{creator}</p>
  )
}

const AddVideoCreatorButton = ()=> {
  const record = useRecordContext();
  if (!record) return <></>;
  return(
    <AddVideoCreatorDialog  videoId={record['videoId']} ownedChannelsId = {record['ownedChannelsId']} />
  )
}

const ClearCompareVideosButton =()=>{
  const {filterValues} = useListContext()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  
  const deleteComparableVideos = async ()=>{
     const result = await dataProvider.deleteComparableVideos(filterValues.Channel)
      if(result){
          notify(' Compare list cleared', {type:'success'})
      }
  }
  
  return <Button onClick={(e)=>deleteComparableVideos()} label='Clear Compare List'/>
}
const Thumbnail = () =>{
  const record = useRecordContext()
  if(!record) return null;
  let thumbnailHighRes = record['thumbnail'].replace('default','maxresdefault')
  return (
    <a href={thumbnailHighRes} target='_blank' rel='noreferrer'>
      <img src={record['thumbnail']} alt ={record['title']} style = {{height:'6em'}}/>
    </a>
)
}
const Link = ({source}) =>{
  const record = useRecordContext()
  return record ? (
      <a href={'https://www.youtube.com/watch?v='+record['videoId']} target='_blank' rel='noreferrer'>
      {record['title']}
    </a>
) : null;
}

const HelperText = ({helperText, threshold}) =>{
  let text = "."
  switch (helperText) {
    case 'Title':
      text = "Title: The title of a video."
      break;
    case 'Published':
        text = "Published: The publish date of a video."
        break;
    case 'Duration':
        text = "Duration: The duration of a video."
        break;
    case 'AVT':
      text = "AVT: The time a video gets watched on average."
      break;
    case 'Retention':
      text = `Retention: The percentage of a video a viewer watches on average at ${threshold} views.`
      break;
    case 'Views':
      text = "Views: The total amount of views since a video was published."
      break;
    case '30sec':
      text = `30sec: The percentage of viewers that are still watching after 30 seconds at ${threshold} views.`
      break;
    case 'Score':
      text = "Score: Calculated by looking at the retention 12 different moments in the first 60 seconds. The higher the score the better."
      break;  
    case '2h':
      text = "2h: The number of views the video had in the last two hours."
      break;
    case '24h':
      text = "24h: The number of views the video had in the last 24 hours."
      break;
    case 'CTR':
      text = "CTR: The percentage of viewers that clicked on the video thumbnail after seeing it."
      break;
      case "Creators":
      text = "Creators: The people that worked on the video."
      break;
    default:
      break
  }
  return <p style={{fontSize:"12px", textAlign:"center", color:"green"}}>{text === "."?<span style={{color:"white"}}>.</span>: <>{text}</>}</p>
}
const helpers =(event)=>{
  // Check if the event object and target property exist
  if (!event || !event.target) return null;

  // Find the innermost span element
  let targetElement = event.target;
  while (targetElement) {
    if (targetElement.tagName.toLowerCase() === 'span' && !targetElement.children.length) {
      // Return the text content of the innermost span
      return targetElement.textContent || null;
    }

    // If the targetElement has children, consider the first child as the next targetElement
    if (targetElement.children.length) targetElement = targetElement.children[0];
    else targetElement = null; // Break out of the loop if there are no children
  }

  return null;
}

    
