import * as React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid from '@mui/system/Unstable_Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  AutocompleteInput,
  useInput,
  useNotify,
  useDataProvider,
  SimpleForm,
  useDelete,
  SaveButton,
   } from 'react-admin';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function AddVideoCreatorDialog({ownedChannelsId, videoId}) {
    const [open, setOpen] = React.useState(false);
    const[videoCreators, setVideoCreators] = React.useState([])
    const [totalViewsPercentage, setTotalViewsPercentage] = React.useState(0);
    const[videoCreatorsToDelete, setVideoCreatorsToDelete] = React.useState([])
    const dataProvider = useDataProvider();
    const notify = useNotify()
    
    React.useEffect(() => { 
        if(open){
            (async () => {
                const videoCreatorData = await dataProvider.getOwnVideoCreators({videoId: videoId})
                setVideoCreators(videoCreatorData.data)
                //console.log(videoCreatorData.data)
                if(videoCreatorData.data.length === 0)
                    setTotalViewsPercentage(0)
                else
                    setTotalViewsPercentage(videoCreatorData.data.map(item => item.percentage).reduce((currentSum, next) => currentSum + parseInt(next)))
            })()
        }
    }, [open])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if(totalViewsPercentage !== 100){
            notify('Total views percentage must be 100')
            return
        }
        (async () => {
            //console.log(videoCreators)
            const videoCreatorsToAdd = videoCreators?.filter(item => item.id === -1)
            const videoCreatorsToUpdate = videoCreators?.filter(item => item.id !== -1)
            if(videoCreatorsToAdd.length > 0)
                await dataProvider.create("videoCreator",{videoCreators: videoCreatorsToAdd})
            if(videoCreatorsToDelete.length > 0)
                await dataProvider.deleteMany("videoCreator",{ids: videoCreatorsToDelete})
            await dataProvider.update("videoCreator",{videoCreators: videoCreatorsToUpdate})
            setVideoCreatorsToDelete([])
            setVideoCreators([])
            notify('Video Creators Updated')
        })()
        setOpen(false);
    }

    const addVideoCreator = (user, role, percentage) => {
        const videoCreator = {
            id:-1,
            videoCreators_id:user.id,
            role_id:role.id,
            percentage:percentage,
            video_id:videoId,
            ownedChannels_id:ownedChannelsId,
            name:user.name,
            role:role.name
        }
         setVideoCreators([...videoCreators, videoCreator])
         setTotalViewsPercentage(totalViewsPercentage + parseInt(percentage))
    }
    const deleteVideoCreator = (videoCreator) => {
        setVideoCreators(videoCreators.filter(item => item.id !== videoCreator.id))
        setTotalViewsPercentage(totalViewsPercentage - parseInt(videoCreator.percentage))
        if(videoCreator.id !== -1)
            setVideoCreatorsToDelete([...videoCreatorsToDelete, videoCreator.id])
    }

    return (
        <div>
            <MaterialButton  onClick={handleClickOpen}>
                <PersonAddIcon/>
            </MaterialButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Video Creators
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <VideoCreatorsEdit videoCreators ={videoCreators} setVideoCreators = {setVideoCreators} deleteVideoCreator = {deleteVideoCreator} setTotalViewsPercentage = {setTotalViewsPercentage}/>
                    <VideoCreatorsAdd open={open} totalViewsPercentage={totalViewsPercentage} addVideoCreator={addVideoCreator}/>
                 </DialogContent>
                 <DialogActions>
                    <MaterialButton autoFocus onClick={handleSave}>
                        Save changes
                    </MaterialButton>
                </DialogActions>
            </BootstrapDialog>
        </div>
  );
}
const VideoCreatorsAdd = ({open, totalViewsPercentage, addVideoCreator}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const[users, setUsers] = React.useState([])
    const[roles, setRoles] = React.useState([])
    const [videoCreator, setVideoCreator] = React.useState('')
    const [role, setRole] = React.useState('')
    const[percent,setPercent] = React.useState(100)

    React.useEffect(() => { 
        if(open){
            (async () => {
                const userData = await dataProvider.getList('userData', {pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
                setUsers(userData.data.map(user => ({id: user.id, name: user.name})))
                //console.log(users)
                const roleData = await dataProvider.getList('roles', {pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
                setRoles(roleData.data.map(role => ({id: role.id, name: role.name, percentage: role.default_percentage})))
                //console.log(roleData)
            })()
        }
    }, [open])

    const handleRoleChange =(role) => {
        setRole(role)
        if(role === ''){
          setPercent(0)
          return
        }
        let selectedRole = roles.find(o => o.id === role);
        setPercent(parseInt(selectedRole.percentage))
      }

    const handleSave = () => {
        if(parseInt(totalViewsPercentage) + parseInt(percent) > 100){
            notify('Total Percent of Views is greater than 100%!', 'warning')
            return
        }

        let selectedRole = roles.find(o => o.id === role);
        if(selectedRole === undefined){
            notify('Please select a Role', 'warning')
            return
        }
        let selectedVideoCreator = users.find(o => o.id === videoCreator);
        if(selectedVideoCreator === undefined){
            notify('Please select a Video Creator', 'warning')
            return
        }
        addVideoCreator(selectedVideoCreator, selectedRole, parseInt(percent))
    }

    return(
        <SimpleForm toolbar={false}>
                <Typography variant="b" gutterBottom>
                New Video Creator
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <AutocompleteInput  source="videoCreator" label="Video Creator" choices={users} onChange={content => setVideoCreator(content)} />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <AutocompleteInput  source="role" label="Role" choices={roles} onChange={content => handleRoleChange(content)} />
                </Box>
            </Box>
            <Box display={{ xs: 'flex', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <Percentage source = "viewsPercentage" percent={percent} setPercent={setPercent}/>
                </Box>
                <Box display='flex' justifyContent='center' flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <SaveButton autoFocus onClick={handleSave} label= "Add Creator"/>
                </Box>
            </Box>
            </SimpleForm>
    )
}
const Percentage =({source, percent, setPercent})=>{
    const { id, field, fieldState } = useInput({ source });
   // return <NumberInput source="percentage" label="Percent of Views"  value={percent} min={0} max={100} step={5} sx={{minWidth:"200px"}}/>
    return (
        <label htmlFor={id}>
            <TextField id={id} {...field} value={percent} label={"Percent of Views"} onChange={content =>setPercent(content.target.value)}/>
            {fieldState.error && <span>{fieldState.error.message}</span>}
        </label>
    );
}

const VideoCreatorsEdit = ({videoCreators,setVideoCreators, deleteVideoCreator, setTotalViewsPercentage}) => {
    const notify = useNotify()
   

    const handleUserChange = (newValue, index) =>{
        if(newValue === ''){
            newValue = 0
        }
        const oldValue = videoCreators[index].percentage
        let newVideoCreators = [...videoCreators]
        newVideoCreators[index].percentage = parseInt(newValue)
        const totalPercent = newVideoCreators.map(item => item.percentage).reduce((currentSum, next) => currentSum + parseInt(next))
        if(totalPercent >100){
            notify('Error: Total percentage cannot exceed 100%', 'WARNING')
            newVideoCreators[index].percentage = oldValue
            console.log(oldValue+" "+totalPercent)
        }
        else{
            setVideoCreators(newVideoCreators)
            setTotalViewsPercentage(totalPercent)    
        }
    }

    const handleUserDelete = (videoCreator) => {
        deleteVideoCreator(videoCreator)
    }
    return (
        <>
         {videoCreators.map((videoCreator, index) => (
            <Grid key={index} container spacing={2} justify="flex-end" alignItems="center" >
                <Grid xs={3}>{videoCreator.name}</Grid>
                <Grid xs={3}>{videoCreator.role.charAt(0).toUpperCase() + videoCreator.role.slice(1)}</Grid>  
                <Grid xs={5}><TextField label="Percent of Views" value={videoCreators[index].percentage} onChange={e=>handleUserChange(e.target.value,index)} /></Grid>
                <Grid xs={1}> <IconButton aria-label="close" onClick={e =>handleUserDelete(videoCreator)}><CloseIcon /></IconButton></Grid>
            </Grid>
        ))}
        <Divider/>
        </>
    )
}

// export function AddVideoCreatorDialog({videoId}) {
//     const [open, setOpen] = React.useState(false);
//     const [totalViewsPercentage, setTotalViewsPercentage] = React.useState(0);
//     const [reload, setReload] = React.useState(false);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return (
//         <div>
//             <MaterialButton  onClick={handleClickOpen}>
//                 Manage Creators
//             </MaterialButton>
//             <BootstrapDialog
//                 onClose={handleClose}
//                 aria-labelledby="customized-dialog-title"
//                 open={open}
//             >
//                 <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
//                 Video Creators
//                 </BootstrapDialogTitle>
//                 <DialogContent dividers>
//                     <VideoCreatorsEditor open = {open} videoId={videoId} totalViewsPercentage ={totalViewsPercentage} setTotalViewsPercentage={setTotalViewsPercentage} reload={reload}/>
//                     <VideoCreatorsAdd open = {open} videoId={videoId} totalViewsPercentage ={totalViewsPercentage} setReload={setReload}/>
//                 </DialogContent>
//             </BootstrapDialog>
//         </div>
//   );
// }
// const VideoCreatorsAdd = ({videoId, open, totalViewsPercentage, setReload}) => {
//     const dataProvider = useDataProvider();
//     const notify = useNotify();
//     const[users, setUsers] = React.useState([])
//     const[roles, setRoles] = React.useState([])
//     const [videoCreator, setVideoCreator] = React.useState('')
//     const [role, setRole] = React.useState('')
//     const[percent,setPercent] = React.useState(100)

//     React.useEffect(() => { 
//         if(open){
//             (async () => {
//                 const userData = await dataProvider.getList('userData', {pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
//                 setUsers(userData.data.map(user => ({id: user.id, name: user.name})))
//                 //console.log(users)
//                 const roleData = await dataProvider.getList('roles', {pagination: {page: 1, perPage: 1000}, sort: {field: 'name', order: 'ASC'}})
//                 setRoles(roleData.data.map(role => ({id: role.id, name: role.name, percentage: role.default_percentage})))
//                 //console.log(roleData)
//             })()
//         }
//     }, [open])

//     const handleRoleChange =(role) => {
//         setRole(role)
//         if(role === ''){
//           setPercent(0)
//           return
//         }
//         let selectedRole = roles.find(o => o.id === role);
//         setPercent(selectedRole.percentage)
//       }

//     const handleSave = () => {
//         if(parseInt(totalViewsPercentage) + parseInt(percent) > 100){
//             console.log(totalViewsPercentage + " " + percent +" "+totalViewsPercentage + percent)
//             notify('Total Percent of Views is greater than 100%!', 'warning')
//             return
//         }
//       dataProvider.create("videoCreator", {videoId: videoId, userId: videoCreator, roleId: role, percentage: percent})
//       .then(() => {
//         notify('Creator created')
       
//         setPercent(0)
//         setReload(Math.random())
//       })
//       .catch(error => {
//         notify('Error: Creator not added', 'warning')
//       })
//     }

//     return(
//         <SimpleForm toolbar={false}>
//                 <Typography variant="b" gutterBottom>
//                 New Video Creator
//             </Typography>
//             <Box display={{ xs: 'block', sm: 'flex' }}>
//                 <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
//                 <AutocompleteInput  source="videoCreator" label="Video Creator" choices={users} onChange={content => setVideoCreator(content)} />
//                 </Box>
//                 <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
//                 <AutocompleteInput  source="role" label="Role" choices={roles} onChange={content => handleRoleChange(content)} />
//                 </Box>
//             </Box>
//             <Box display={{ xs: 'flex', sm: 'flex' }}>
//                 <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
//                 <Percentage source = "viewsPercentage" percent={percent} setPercent={setPercent}/>
//                 </Box>
//                 <Box display='flex' justifyContent='center' flex={1} ml={{ xs: 0, sm: '0.5em' }}>
//                 <SaveButton autoFocus onClick={handleSave}/>
//                 </Box>
//             </Box>
//             </SimpleForm>
//     )
// }

// const VideoCreatorsEditor = ({open, videoId, totalViewsPercentage, setTotalViewsPercentage, reload}) => {
//     const dataProvider = useDataProvider();
//     const [deleteOne, { isLoading, error }] = useDelete();
//     const notify = useNotify()
//     const[videoCreators, setVideoCreators] = React.useState([])
//     React.useEffect(() => { 
//         if(open){
//             (async () => {
//                 const videoCreatorData = await dataProvider.getOwnVideoCreators({videoId: videoId})
//                 setVideoCreators(videoCreatorData.data)
//                 if(videoCreatorData.data.length === 0)
//                     setTotalViewsPercentage(0)
//                 else
//                     setTotalViewsPercentage(videoCreatorData.data.map(item => item.percentage).reduce((currentSum, next) => currentSum + parseInt(next)))
//             })()
//         }
//     }, [open, reload])

//     const handleUserChange = (newValue, index) =>{
//         const oldValue = videoCreators[index].percentage
//         let newVideoCreators = [...videoCreators]
//         newVideoCreators[index].percentage = newValue
//         const totalPercent = newVideoCreators.map(item => item.percentage).reduce((currentSum, next) => currentSum + parseInt(next))
//         if(totalPercent >100){
//             notify('Error: Total percentage cannot exceed 100%', 'WARNING')
//             newVideoCreators[index].percentage = oldValue
//             console.log(oldValue+" "+totalPercent)
//         }
//         else{
//             setVideoCreators(newVideoCreators)
//             setTotalViewsPercentage(totalPercent)    
//         }
//     }
//     const handleUserEdit = (videoCreator) => {
//         dataProvider.update("videoCreator", {videoId: videoId, userId: videoCreator.user_id, roleId: videoCreator.role_id,percentage: videoCreator.percentage}).then(result=>{
//             notify('Creator updated')   
//         }).catch(error => {
//             notify('Error: Creator not updated', 'warning')
//         })
//     }
//     const handleUserDelete = (videoCreator) => {
//         (async () => {
//             await deleteOne("videoCreator", {meta:{videoId: videoId, userId: videoCreator.user_id, roleId: videoCreator.role_id}})
//             setTotalViewsPercentage(totalViewsPercentage - videoCreator.percentage)
//             setVideoCreators(videoCreators.filter(item => (item.user_id !== videoCreator.user_id && item.role_id !== videoCreator.role_id)))
//         })()
//     }
//     return (
//         <>
//          {videoCreators.map((videoCreator, index) => (
//             <Grid key={index} container spacing={2} justify="flex-end" alignItems="center" >
//                 <Grid xs={3}>{videoCreator.name}</Grid>
//                 <Grid xs={3}>{videoCreator.role.charAt(0).toUpperCase() + videoCreator.role.slice(1)}</Grid>  
//                 <Grid xs={4}><TextField label="Percent of Views" value={videoCreators[index].percentage} onChange={e=>handleUserChange(e.target.value,index)} /></Grid>
//                 <Grid xs={1}> <IconButton aria-label="close" onClick={e =>handleUserEdit(videoCreator)}><EditIcon /></IconButton></Grid>
//                 <Grid xs={1}> <IconButton aria-label="close" onClick={e =>handleUserDelete(videoCreator)}><CloseIcon /></IconButton></Grid>
//             </Grid>
//         ))}
//         <Divider/>
//         </>
//     )
// }

// const Percentage =({source, percent, setPercent})=>{
//     const { id, field, fieldState } = useInput({ source });
//    // return <NumberInput source="percentage" label="Percent of Views"  value={percent} min={0} max={100} step={5} sx={{minWidth:"200px"}}/>
//     return (
//         <label htmlFor={id}>
//             <TextField id={id} {...field} value={percent} label={"Percent of Views"} onChange={content =>setPercent(content.target.value)}/>
//             {fieldState.error && <span>{fieldState.error.message}</span>}
//         </label>
//     );
// }
