import {SimpleShowLayout, 
    Show, 
    useShowContext, 
    useRecordContext, 
    useDataProvider, 
    useNotify, 
    Button, 
    TopToolbar, 
    ListButton} from 'react-admin';
import{getOwnVideo} from '../../admin/dataProviders/ownChannelsDataSource'
import React, {useRef,useEffect, useState} from "react";
import { Content } from './ownVideoContent';
import {ThumbnailsEdit} from './thumbnails'
import {Chart, LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler} from 'chart.js'
Chart.register(LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler)

 export const OwnVideoShow = () => {
        const[videoSeconds,setVideoSeconds] = useState(0)
        const[clearCount, setClearCount] = useState(0)
        const [reload, setReload] = useState(0)

        window.onpopstate = ()=>{
            setReload(Math.random())
        }

        return (
            <>
            <Show title= {<Title/>}>
                <TopToolbar>
                    <ListButton />
                </TopToolbar>
                <SimpleShowLayout>
                    <VideoGraph reload ={reload} setVideoSeconds={setVideoSeconds}/>
                    <Content setReload ={setReload} videoSeconds ={videoSeconds} clearCount={clearCount}/>
                    <Thumbnails/>
                </SimpleShowLayout>
            </Show>
            </>
        );
    }

const Title = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>{record.title}</span>;
}

const Thumbnails = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <ThumbnailsEdit id= {record.id} videoId={record.videoId}/>;
}
    
const ClearCompareVideosButton =({setClearCount})=>{
    const data = useShowContext()
    const notify = useNotify()
    const dataProvider = useDataProvider()
    
    const deleteComparableVideos = async ()=>{
        setClearCount(Math.random())
       const result = await dataProvider.deleteComparableVideos(data.record.channelId)
        if(result){
            notify(' Compare list cleared', {type:'success'})
        }
    }
    
    return <Button onClick={(e)=>deleteComparableVideos()} label='Clear Compare List'/>
  }

let  myChart = ""
const VideoGraph =({reload, setVideoSeconds})=>{
    const dataProvider = useDataProvider()
    const {record} = useShowContext()
    const ctx = useRef()

    useEffect(()=>{
        loadChart()
        return ()=>{
            if(myChart instanceof Chart)
            {
                myChart.destroy();
            }
        }
    },[reload])

    const loadChart = async ()=>{
        if (!record) return <></>; 
        //Have to be called because by default getOne uses the cached result from the list view, and we don't send the retention there
        const result = await getOwnVideo({id:record.id})
        const retention = result.data.retention
        if(!retention) return <></>
        Tooltip.positioners.bottom = function(items, eventPosition) {
            const chart = this.chart;         
            return {
              x: chart.chartArea.left,
              y: chart.chartArea.bottom,
              xAlign: 'center',
              yAlign: 'bottom',
            };
        }
        let labels = []
        for (let inc=0; inc<retention?.length*5;inc+=5){
            const paramName = secondsToHms(inc)
            labels.push(paramName)
        }
         
        const channelAverage = await dataProvider.getChannelAverage('ownChannels', {channelId:record.ownedChannelsId})
        const comparableVideos = await dataProvider.getComparableVideos(record.channelId)
        const comparableVideo1 = comparableVideos.data && comparableVideos.data[0] ? {title: comparableVideos.data[0].video.title, retention: comparableVideos.data[0].video.retention} : {title:'', retention: null}
        const comparableVideo2 = comparableVideos.data && comparableVideos.data[1] ? {title: comparableVideos.data[1].video.title, retention: comparableVideos.data[1].video.retention} : {title:'', retention: null}
        const comparableVideo3 = comparableVideos.data && comparableVideos.data[2] ? {title: comparableVideos.data[2].video.title, retention: comparableVideos.data[2].video.retention} : {title:'', retention: null}
        const comparableVideo4 = comparableVideos.data && comparableVideos.data[3] ? {title: comparableVideos.data[3].video.title, retention: comparableVideos.data[3].video.retention} : {title:'', retention: null}

        if(myChart instanceof Chart)
        {
            myChart.destroy();
        }
        myChart = new Chart(ctx.current, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: record.title,
                    data: retention,
                    fill: false,
                    borderColor: 'rgb(53, 92, 125)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    tension: 0
                },
                {
                    label: comparableVideo1.title,
                    data: comparableVideo1.retention,
                    fill: false,
                    borderColor: 'rgb(248, 111, 21)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    tension: 0
                },
                {
                    label: comparableVideo2.title,
                    data: comparableVideo2.retention,
                    fill: false,
                    borderColor: 'rgb(250, 194, 24)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    tension: 0
                },
                {
                    label: comparableVideo3.title,
                    data: comparableVideo3.retention,
                    fill: false,
                    borderColor: 'rgb(222, 179, 173)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    tension: 0
                },
                {
                    label: comparableVideo4.title,
                    data: comparableVideo4.retention,
                    fill: false,
                    borderColor: 'rgb(245, 22, 99)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    tension: 0
                },
                {
                    label: 'Channel Average',
                    data: channelAverage.data.averageRetention,
                    fill: true,
                    borderColor: 'rgb(211,211,211)',
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    tension: 0
                }
            ]
            },
            options: {
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                title: {
                    display: true,
                    text: 'Retention'
                },
                plugins: {
                    tooltip: {
                        position: "bottom",
                        enabled: true,
                        usePointStyle: true,
                        callbacks: {
                            footer: function(tooltipItem, data){
                                let index = tooltipItem[0].index;
                                //console.log(tooltipItem)
                            }
                          }
                  }
                },
                onClick: function(evt, activeElements) {
                     if(activeElements[0].datasetIndex === 0){
                         setVideoSeconds(activeElements[0].index*5)
                        //console.log(`Video: ${this.data.datasets[activeElements[0].datasetIndex].label} at ${this.data.labels[activeElements[0].index]} `)
                     }
                  }
            }
        })
    }
    return <div className = "row"><canvas ref={ctx} width="300" height="130" ></canvas></div>
}

// change seconds to hh:mm:ss
const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);
}

