import { 
    Datagrid,  
    List,  
    TextField,
    ShowButton,
    DateInput,
    TextInput,
    SelectInput,
    useListContext
} from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography'; 
import Grid from '@mui/system/Unstable_Grid';

  export const ViewAnalyticsList = () => (
    <List pagination={false} filters={ListFilters} filterDefaultValues={{ date: getThreeDaysAgo(),team:"all" }} >
        <ViewsHeader/>
        <Datagrid bulkActionButtons={false}>
          <ShowButton/>
          <TextField source="name" />
          <TextField source="date" />
          <TextField source="views" label="Views over all Channels"/>
          <TextField source="viewsMonthToDate" label = "Total Views Current Month" />
        </Datagrid>
    </List>
  );

  const ListFilters = [
    <DateInput source="date" label="Date"  alwaysOn />,
    <TextInput source="searchPhrase" label="Search by Name" alwaysOn />,
    <SelectInput source ='team' choices={[
      { id: 'US', name: 'US' },
      { id: 'VN', name: 'VN' }
    ] }emptyValue={"all"} emptyText="US and VN" initialValue={"all"} alwaysOn/>
  ]

  const ViewsHeader = ()=>{  
    const {data} = useListContext()
    if(!data) return null
    return (
      <><br/>
      <Grid container spacing={2} >
        <Grid xs={3} sx={{marginLeft:"10px"}}>
          <Card>
            <CardContent>
              <Typography><b>Total Daily Views: {(parseInt(data[0]?.totalDailyViewsUS) + parseInt(data[0]?.totalDailyViewsVN)).toLocaleString()}  </b> </Typography>
              <Typography>US: {data[0]?.totalDailyViewsUS.toLocaleString()}</Typography>
              <Typography>VN: {data[0]?.totalDailyViewsVN.toLocaleString()}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={3} sx={{marginLeft:"10px"}}>
          <Card>
            <CardContent>
              <Typography variant="b"><b>Total Views this Month to Date: {(parseInt(data[0]?.totalViewsMonthToDateUS) + parseInt(data[0]?.totalViewsMonthToDateVN)).toLocaleString()} </b> </Typography>
              <Typography>US: {data[0]?.totalViewsMonthToDateUS.toLocaleString()}</Typography>
              <Typography>VN: {data[0]?.totalViewsMonthToDateVN.toLocaleString()}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br/>
      </>
    )
  }

  const getThreeDaysAgo = ()=>{
    const date = new Date()
    date.setDate(date.getDate()-3)
    return date.toISOString().split('T')[0]
}