import axios from 'axios'
import { HttpError } from 'react-admin';
import { getS3Bucket } from './awsAccess';
import endpoints from './endpoints.json'


export const chat = async(oldMessages,newMessage)=>{
    let messages
    if (oldMessages)
        messages = oldMessages.concat({role:"user", content:newMessage})
    else
         messages = [{role:"user", content:newMessage}]
    //console.log(messages)
    const {data} = await axios.post(endpoints.backendUrl+endpoints.chatGptEndpoint+"/streamChat", {messages:messages}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    if(data.error) throw new HttpError(data.error.status,data.error.message)
    const updatedOldMessages = messages?.concat(data?.result[0]?.message)
    let answer=""
    for (let i=0; i<updatedOldMessages.length; i++){
        if(updatedOldMessages[i].role==="assistant"){
            answer += updatedOldMessages[i].content.replaceAll("\n","<br/>") + "<br/><br/>"
        }
    }
    return {data:{answer:answer, oldMessages:updatedOldMessages}}
}
export const addChatHistory = async(params)=>{
    params.messages = JSON.stringify(params.messages)
    const {data} = await axios.post(endpoints.backendUrl+endpoints.chatGptEndpoint+"/chatHistory", params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
}
export const getChatHistory = async(params)=>{
    const {data} = await axios.get(endpoints.backendUrl+endpoints.chatGptEndpoint+"/chatHistory",{params:params, headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    if(data.error) throw new HttpError(data.error.status,data.error.message)
    const mappedData = data.map((item)=>{
        const conversation = getConversation(JSON.parse(item.messages))
        let title = JSON.parse(item.messages)[0].content
        if(title.length>20) title = title.substring(0,20)+"..."
        item.title = title
        item.id = item.id
        item.messages = JSON.parse(item.messages)
        item.date = new Date(item.date).toLocaleDateString()
        item.conversation = conversation
        return item
    })
    return {data:mappedData,total:mappedData.length}
}

export const deleteChatGptHistory = async(params)=>{
   // console.log(params)
    const {data} = await axios.delete(endpoints.backendUrl+endpoints.chatGptEndpoint+"/chatHistory",{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params:params})
    if(data.error) throw new HttpError(data.error.status,data.error.message)
    return {data:data}
}

const getConversation = (messages)=>{
    let formattedString = '';
    for (const message of messages) {
        if (message.role === 'user') {
        formattedString += `<br/><p style="color:green">${message.content}</p>`;
        } else if (message.role === 'assistant') {
        formattedString += `<p>${message.content}</p>`;
        }
    }
    return formattedString;
}

export const combineImages = async(params)=>{  
    if(!params.data.images && params.data.imageUrls?.length === 0) throw("You need to select at least one image") 
    let urls = []
    if(params.data.imageUrls?.length > 0)
        urls = params.data.imageUrls.split('\n')
    else
        urls = await uploadImages('aiSourceImages', params.data.images)
    try{
        const {data} = await axios.post(endpoints.backendUrl+endpoints.chatGptEndpoint+"/combineImages", {images:urls, owner:params.data.owner}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
        return({data:{id:1,sourceImages: urls, generatedImage:data.imageUrl}})
    }
    catch(err){
        handleError(err)
    }
}
export const createImage = async(params)=>{
    try{
        const {data} = await axios.post(endpoints.backendUrl+endpoints.chatGptEndpoint+"/createImage", {prompt:params.data.prompt, owner:params.data.owner}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
        return({data:{id:2,generatedImage:data.imageUrl}})
    }
    catch(err){
        handleError(err)
    }
}
export const createImageVariations = async(params)=>{
    console.log(params)
    if(!params.data.images && !params.data.imageUrls && !params.data.imageUrl) throw("You need to select at least one image")
    let urls = []
    if(params.data.imageUrls)
        urls = params.data.imageUrls
    else if(params.data.imageUrl)
        urls = [params.data.imageUrl]
    else
        urls = await uploadImages('aiSourceImages', [params.data.images])
    try{
        const {data} = await axios.post(endpoints.backendUrl+endpoints.chatGptEndpoint+"/createImageVariation", {image:urls[0], prompt:params.data.prompt, owner:params.data.owner}, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
        return({data:{id:1,sourceImages: urls, generatedImage:data.imageUrl}})
    }
    catch(err){
        handleError(err)
    }
}

const uploadImages = async(folder, images)=>{    

    const date = Date.now()
    const myBucket = await getS3Bucket()
    const urls =[]
    images = images.filter(image=>image.rawFile instanceof File)
    for(const image of images){
        let imageTitle = `${image.title}`
        imageTitle = imageTitle.replaceAll(' ','_')
        const fileBuffer = await readFileAsync(image.rawFile)
        const params = {
            Body: fileBuffer,
            Bucket: 'flocal',
            Key: `${folder}/_temp_${date}_${imageTitle}`,
            ContentType: image.rawFile.type
        };
        try{
        await myBucket.upload(params).promise() 
        }catch(err){
            console.log(err)
        }   
        urls.push(`https://flocal.s3-us-west-1.amazonaws.com/${folder}/_temp_${date}_${imageTitle}`)
    }
    return urls
}

export const getAiGeneratedImages = async(params)=>{  
    const {data} = await axios.get(endpoints.backendUrl+endpoints.chatGptEndpoint+`/aiGeneratedImages?owner=${params.filter.owner}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:data, total:data.length}
}

export const deleteAiGeneratedImage = async(params)=>{
    const {data} = await axios.delete(endpoints.backendUrl+endpoints.chatGptEndpoint+`/aiGeneratedImages?url=${params.url}`, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:data}
}

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onabort = () => reject('file reading was aborted')
        reader.onerror = () => reject('file reading has failed')
      reader.onload = () => {
        resolve(reader.result);
      };   
      reader.readAsArrayBuffer(file);
    })
  }

  const handleError = (error) => {
    // Check if the error is an AxiosError
    if (axios.isAxiosError(error)) {
        // Check if there's a response object
        if (error.response) {
          // The server responded with a status code outside the 2xx range
          console.log('Error data:', error.response.data);
          console.log('Error status:', error.response.status);
          console.log('Error headers:', error.response.headers);  
          throw(error.response.data)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('The request was made but no response was received');
          throw('The request was made but the response timed out. Please refresh the site.')
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error', error.message);
          throw('Error', error.message)
        }
    } 
    else {
        // The error is not an AxiosError
        console.log('Unexpected error:', error);
        throw('Error:', error)
    }
};

