import React,{useState, useEffect} from "react";
import { 
    Card, 
    CardContent
 } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { SelectChannel } from "./miscellanious/utils";
 import 
 {  
    Link, 
    useDataProvider, 
    usePermissions,
    } 
    from 'react-admin';


const Dashboard = () => {
    const [channel, setChannel] = useState({id: 1, name: 'Toys and Colors', project: '_kids'})
    const {permissions} = usePermissions()
    return(
    <>
    { (permissions !== 'external')  &&
    <Grid container>
        <Grid xs={12}>
        <SelectChannel channel ={channel} setChannel={setChannel}/>
        </Grid>
        <Grid xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2} >
                        <BestExternalPerformers channel={channel} filter='viewsLast2h' title='Most Views in the Last 2 Hours'/>
                        <BestExternalPerformers channel={channel} filter='videoPerformance' title='Best Performing Videos'/>
                        <BestRelatedVideos channel={channel} title='Videos sending the most traffic to channel'/>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    }
    </>
);
}
export default Dashboard;

const BestExternalPerformers =({channel, filter, title})=>{
    const dataProvider = useDataProvider()
    const [data, setData] = useState([])
    useEffect(() => {
        //console.log(channel)
        if(channel.id){
            const project = channel.project?.length > 0 ? channel.project:'_kids'
            const data = dataProvider.getList('externalVideos', {pagination: {page: 1, perPage: 4}, filter:{project: project}, sort: {field: filter, order: 'DESC'}})
            .then(({ data }) => {
                //console.log("Videos: ",data)
                return dataProvider.translate(data)
            }).then((data) => {
                setData(data)
                //console.log("Translated:", data)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [channel])
    if (!data) { return <p>Loading..</p>; }

    return (        
        <Grid xs={12}>
             <Grid container spacing={2} >
                <Grid xs={12}><b >{title}</b></Grid>
                {data.map((row, index) => (       
                <Grid xs={3}>
                    <Grid container spacing={2} key={index} >
                        <Grid xs={12} style={{maxHeight:"50px", minHeight:"50px", overflow:"hidden"}}>< div dangerouslySetInnerHTML={{__html: row.title}}></div></Grid> 
                        <Grid xs={12}><Link to={`/externalVideos/${row.id}/show`}><img src={row.thumbnail.replace('default','maxresdefault')} alt={row.title} style={{width:"90%"}} /></Link></Grid>
                    </Grid>
                </Grid>                  
            ))}  
            </Grid>       
        </Grid>      
    );
}
const BestRelatedVideos =({channel, title})=>{
    const [relatedVideos, setRelatedVideos] = useState([])
    const dataProvider = useDataProvider()
    useEffect(() => {
       // console.log(channel)
        if(channel.id){
            const data = dataProvider.getList('relatedVideos', {ownedChannelsId: channel.id})
            .then(({ data }) => {
                const filteredData = filterRelatedVideos(data)
                setRelatedVideos(filteredData)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [channel])
    return (        
        <Grid xs={12}>
             <Grid container spacing={2} >
             <Grid xs={12}><b >{title}</b></Grid>
                {relatedVideos.map((row, index) => (       
                <Grid xs={3}>
                    <Grid container spacing={2} key={index} >
                        <Grid xs={12}><YoutubeEmbed videoId={row.videoId} width={320} height={240}/></Grid> 
                        <Grid xs={12} style={{textAlign:"center"}}><b>Generated Views: {row.views}</b></Grid>
                    </Grid>
                </Grid>                  
            ))}  
            </Grid>       
        </Grid>      
    );
}

const filterRelatedVideos = (data) => {
    const filteredData = data.filter((record) => record.isExternal)
    return filteredData.splice(0, 4)
}
const YoutubeEmbed = ({ videoId, width, height }) => (
    <div className="video-responsive">
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
